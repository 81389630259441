import React from 'react'
import {Link} from 'gatsby'
import logo from '../assets/images/logo.jpg'

const ComingSoon = () => {

    React.useEffect(() => {
      const script = document.createElement('script');
      script.src = '//js.hsforms.net/forms/embed/v2.js';
      document.body.appendChild(script);
  
      script.addEventListener('load', () => {
      if (window.hbspt) {
          window.hbspt.forms.create({
          region: "na1",
          portalId: "7346852",
          formId: "53512b29-1d67-4c9e-93a3-dfbe9594a7c6",
          target: ".newsletter-form"
          });
      }
      });
    }, [])


    return (
        <React.Fragment>
            <div className="coming-soon-area">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="coming-soon-content">
                            <Link to="/" className="logo">
                                <img src={logo} alt="logo" />
                            </Link>
                            <form className="newsletter-form"></form>
                            <p>If you want to be in the loop, we'll let you know our progress. We promise to never spam.</p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ComingSoon